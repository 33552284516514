<template>  
  <div class="home container">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>

    <HomeCarusel class="hide-on-mobile" />
    
    <CategoriesMenu />

    <!--Polular NOW Boxes-->
    <PopularNow />
    
    <RecentAdvertCarusel />
    
    <!--Place for Banner-->
    <GeneralBanner :bannerType="2"/>   
    <!--END Banner-->
    
    <div class="grid-list-bt hide-on-mobile" ref="scrollancor">
      <b-field>
        <b-radio-button
          v-model="displayType"
          native-value="grid"
          type="is-success is-light"
        >
          <b-icon icon="grid-2"></b-icon>
          <span>{{ $t("Grid") }}</span>
        </b-radio-button>

        <b-radio-button
          v-model="displayType"
          native-value="list"
          type="is-success is-light"
        >
          <b-icon icon="align-justify"></b-icon>
          <span>{{ $t("List") }}</span>
        </b-radio-button>
      </b-field>
    </div>

    <div v-if="displayType == 'grid'" class="advetrs-grid my-3">
      <div class="advert-item" v-for="advert in advertsData" :key="advert.id">
        <AdvertCard :advertData="advert" />
      </div>
    </div>

    <AdsList
      class="mt-4 pt-4"
      v-if="displayType == 'list'"
      :listItems="advertsData"
    />

    <div class="my-4 products-pagination">
      <b-pagination
        :total="totalItems"
        v-model="currentPage"
        :range-before="1"
        :range-after="3"
        :per-page="numberOfItems"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        @change="navigateAds"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CategoriesMenu from "@/components/navigation/CategoriesMenu.vue";
import AdvertCard from "@/components/adverts/AdvertCard.vue";
import AdsList from "@/components/adverts/AdsList.vue";

import RecentAdvertCarusel from "@/components/adverts/RecentAdvertCarusel.vue";
import HomeCarusel from "@/components/banners/HomeCarusel.vue";
import GeneralBanner from "@/components/banners/GeneralBanner.vue";

import PopularNow from "@/components/adverts/PopularNow.vue";

export default {
  name: "HomeView",
  components: {
    CategoriesMenu,
    AdvertCard,
    AdsList,
    RecentAdvertCarusel,
    //banners
    HomeCarusel,
    GeneralBanner,
    PopularNow,
  },
  data() {
    return {
      advertsData: [],
      isEmpty: false,
      isLoading: false,
      currentPage: null,
      totalItems: 0,
      displayType: "grid",
      numberOfItems: 32,
    };
  },
  computed:{
    isMobile() {
      return this.$store.state.isMobile;
    },
    pageMeta() {
      return {
        title: this.$t('home.title'),
        metaTags: [
          {
            name: "description",
            content: this.$t('home.description'),
          },
          {
            property: "og:description",
            content: this.$t('home.description'),
          },
        ],
      };
    },
  },
  beforeMount() {
    if(this.isMobile){
      this.numberOfItems = 16;
    }
    console.log("beforeMount");
    if (!this.currentPage) {
      this.currentPage = 1;
    }
    if (this.$route.query.page) {
      this.currentPage = parseInt(this.$route.query.page);
    }
    this.loadData();
    //window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    //window.removeEventListener("scroll", this.handleScroll);
   // localStorage.setItem("scrollY", window.scrollY);
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem('scrollY', window.scrollY);
    next();
  },
  mounted() {
    console.log("mounted");
    //if(localStorage.getItem("scrollY")){
    // if(this.$store.state.didPressBack){
    //   this.$store.commit("setDidPressBack", false);
    //   this.scrollHistory();
    // }
  },
  methods: {
    // handleScroll() {
    //   //console.log("scrolling...", window.scrollY);
    //   localStorage.setItem("scrollY", scrollY);
    // },
    // scrollHistory() {     
    //   let scrollY = localStorage.getItem("scrollY");
    //   localStorage.removeItem("scrollY");
    //   setTimeout(function () {
    //     window.scrollTo(0, scrollY);
    //   }, 300); 
    // },

    search: function () {
      console.log("search");
    },
    loadMore: function () {
      this.currentPage++;
      this.loadData();
    },
    navigateAds() {
      this.$router.push({ query: { page: this.currentPage } });
      console.log("navigateAds");
      const element = this.$refs.scrollancor;
      let elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
        if(this.isMobile){
          elementPosition = 700;
        }
      // window.scrollTo({ top: elementPosition - 150, behavior: "smooth" });
      setTimeout(function () {
        // window.scrollTo(0, 0);
        window.scrollTo({ top: elementPosition - 100, behavior: "smooth" });
      }, 2);
      //this.loadData();
    },
    getSearchResults(data) {
      console.log("got Search data: " + data);

      // this.search = search;
      // this.currentPage = 1;
      // this.loadData();
    },
    loadData() {
      this.isLoading = true;
      this.axios
        .post(`${this.$env.api}/adverts/get_adverts.php`, {
          startID: (this.currentPage - 1) * this.numberOfItems,
          search: this.search,
          searchType: this.filterType,
          itemsNum: this.numberOfItems,
          action: "listItems",
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.success) {
            this.advertsData = response.data.items;
            this.isEmpty = false;

            this.totalItems = response.data.count;
            console.log("Total Result:" + this.advertsData.length);
          } else {
            this.hasItems = false;
            console.log("Error getting data");
            this.advertsData = [];
            this.totalItems = 0;
          }
        })
        .catch((error) => {
          this.hasItems = false;
          console.log(error);
          //this.errored = true;
        })
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    $route(to, from) {
      console.log("route changed");
      if (!this.$route.query.page) {
        this.currentPage = 1;
      } else {
        this.currentPage = parseInt(this.$route.query.page);
      }
      this.loadData();
    },
  },
  //meta
  metaInfo() {
    return {
      title: this.pageMeta.title,
      meta: this.pageMeta.metaTags.map(tag => ({
        key: tag.name || tag.property,
        ...tag
      })),
    };
  },
};
</script>

<style scoped>
.call-to-action {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  /* text-transform: uppercase; */
}
.seach-box {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 20px;
  margin-bottom: 40px;
}

/* .home {
  margin-top: 300px;
} */
</style>
